import { FC } from 'react';
import { hasRichTextContent } from '@lib/slice-machine/richText';
import {
  DividerWeight,
  ItemPosition,
  TextColor,
  ButtonBackgroundColor,
} from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Divider, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../constants';
import { RichTextField } from '@prismicio/types';

interface Props {
  title: RichTextField[];
  dividerColor: TextColor | null;
  dividerWeight?: DividerWeight | null;
  showDivider: boolean | null;
  subtitle: RichTextField[];
  position: ItemPosition | null;
  ctaTextLink?: RichTextField[];
  ctaTextColor?: TextColor | null;
  ctaBackgroundColor?: ButtonBackgroundColor | null;
}

export const IntroBlock: FC<Props> = (props) => {
  const {
    title,
    dividerColor,
    position,
    subtitle,
    showDivider,
    dividerWeight,
    ctaTextLink,
    ctaBackgroundColor,
    ctaTextColor,
  } = props;
  const dynamicStyles = styles({
    dividerColor,
    dividerWeight,
    position,
  });

  return (
    <Stack
      sx={dynamicStyles.getValue('container')}
      spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
    >
      {hasRichTextContent(title) && <CustomRichText render={title} />}
      {showDivider && <Divider sx={dynamicStyles.getValue('divider')} />}
      {hasRichTextContent(subtitle) && <CustomRichText render={subtitle} />}
      {hasRichTextContent(ctaTextLink) && (
        <CustomRichText
          render={ctaTextLink}
          linkBtnProps={{
            linkAsButton: true,
            color: ctaBackgroundColor ?? undefined,
            textProps: {
              color: ctaTextColor ?? undefined,
              fontSize: 18,
            },
          }}
        />
      )}
    </Stack>
  );
};

interface StyleProps {
  dividerColor: TextColor | null;
  dividerWeight?: DividerWeight | null;
  position: ItemPosition | null;
}

const styles = ({ dividerColor, dividerWeight, position }: StyleProps) =>
  new SxStyles({
    container: {
      textAlign: position,
    },
    divider: {
      width: 200,
      alignSelf: 'center',
      borderColor: dividerColor,
      ...(dividerWeight === 'bold' && { borderWidth: 3 }),
    },
  });
